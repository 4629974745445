/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../../components/Layout'
import ThemeWrapper from '../../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Subtitle, Title, Button, Text, Image } from '@swp/components'
import SiteHeader from '../../components/hr/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout >
        <SiteHeader />

        <Column className="css-8l2ce6 --style3 --full pb--20" anim={""} name={"intro"} animS={"5"} fullscreen={true} css={css`
        
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/140/fullscreen_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/140/fullscreen_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/140/fullscreen_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/140/fullscreen_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/140/fullscreen_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/140/fullscreen_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/140/fullscreen_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/140/fullscreen_s=3000x_.jpg);
    }
  
      `}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim2 --anim-s8" anim={"2"} animS={"8"}>
              
              <Subtitle className="subtitle-box fs--36" content={"<span style='color: black'>Accommodation</span>"}>
              </Subtitle>

              <Title className="title-box fs--185 w--300 swpf--uppercase ls--0 lh--1" content={"<span style='color: black'>Hozelec</span>"}>
              </Title>

              <Button className="btn-box btn-box--hvr2 fs--20" content={"Available rooms"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"information-1"} style={{"backgroundColor":"var(--color-supplementary)"}}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim2 --anim-s8" anim={"2"} animS={"8"}>
              
              <Title className="title-box fs--62" style={{"maxWidth":614}} content={"<span style=\"color: var(--color-dominant);\">Your comfort is our priority</span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s8 --center el--3 pb--08 pt--16" anim={"2"} animS={"8"} style={{"maxWidth":1300}} columns={"3"}>
            
            <ColumnWrapper anim={""} animS={"2"} style={{"maxWidth":360}}>
              
              <Subtitle className="subtitle-box" content={"<span style=\"color: var(--color-dominant);\">A key or a keycard</span>"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":800}} content={"Click and start writing. This is a text area. If needed, the color, font size, line spacing, text alignment and even the <strong>maximum width of a block of text</strong> may be customized.  If you wish to find out more about editing, click <a href=\"https://saywebpage.com/cs/video-pruvodce-editaci/\">here</a>."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper anim={""} animS={""} style={{"maxWidth":360}}>
              
              <Subtitle className="subtitle-box" content={"<span style=\"color: var(--color-dominant);\">Fully furnished rooms</span>"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":800}} content={"Click and start writing. This is a text area. If needed, the color, font size, line spacing, text alignment and even the <strong>maximum width of a block of text</strong> may be customized.  If you wish to find out more about editing, click <a href=\"https://saywebpage.com/cs/video-pruvodce-editaci/\">here</a>."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper anim={""} animS={"2"} style={{"maxWidth":360}}>
              
              <Subtitle className="subtitle-box" content={"<span style=\"color: var(--color-dominant);\">Luggage assistance</span>"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":800}} content={"Click and start writing. This is a text area. If needed, the color, font size, line spacing, text alignment and even the <strong>maximum width of a block of text</strong> may be customized.  If you wish to find out more about editing, click <a href=\"https://saywebpage.com/cs/video-pruvodce-editaci/\">here</a>."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"information-2"}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s8 --center el--3 flex--center" anim={"2"} animS={"8"} columns={"3"}>
            
            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/i/template/140/img-1_s=350x_.jpg"} svg={false} ratio={"4:3"} sizes={"100vw"} style={{"maxWidth":1080}} srcSet={"https://cdn.swbpg.com/t/i/template/140/img-1_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/140/img-1_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/140/img-1_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/140/img-1_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/140/img-1_s=2000x_.jpg 2000w"} content={null}>
              </Image>

              <Text className="text-box" style={{"maxWidth":399}} content={"Room"}>
              </Text>

              <Title className="title-box mt--0" content={"Economy"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":369}} content={"Click and start writing. This is a text area. If needed, the color, font size, line spacing, text alignment and even the <strong>maximum width of a block of text</strong> may be customized.  If you wish to find out more about editing, click <a href=\"https://saywebpage.com/cs/video-pruvodce-editaci/\">here</a>."}>
              </Text>

              <Subtitle className="subtitle-box" content={"$17 <span style='font-style: italic'>a night</span>"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/i/template/140/img-2_s=350x_.jpg"} svg={false} ratio={"4:3"} sizes={"100vw"} style={{"maxWidth":1080}} srcSet={"https://cdn.swbpg.com/t/i/template/140/img-2_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/140/img-2_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/140/img-2_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/140/img-2_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/140/img-2_s=2000x_.jpg 2000w"} content={null}>
              </Image>

              <Text className="text-box" style={{"maxWidth":399}} content={"Room"}>
              </Text>

              <Title className="title-box mt--0" content={"Economy"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":369}} content={"Click and start writing. This is a text area. If needed, the color, font size, line spacing, text alignment and even the <strong>maximum width of a block of text</strong> may be customized.  If you wish to find out more about editing, click <a href=\"https://saywebpage.com/cs/video-pruvodce-editaci/\">here</a>."}>
              </Text>

              <Subtitle className="subtitle-box" content={"$17 <span style='font-style: italic'>a night</span>"}>
              </Subtitle>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/i/template/140/img-3_s=350x_.jpg"} svg={false} ratio={"4:3"} sizes={"100vw"} style={{"maxWidth":1080}} srcSet={"https://cdn.swbpg.com/t/i/template/140/img-3_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/140/img-3_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/140/img-3_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/140/img-3_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/140/img-3_s=2000x_.jpg 2000w"} content={null}>
              </Image>

              <Text className="text-box" style={{"maxWidth":399}} content={"Room"}>
              </Text>

              <Title className="title-box mt--0" content={"Economy"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":369}} content={"Click and start writing. This is a text area. If needed, the color, font size, line spacing, text alignment and even the <strong>maximum width of a block of text</strong> may be customized.  If you wish to find out more about editing, click <a href=\"https://saywebpage.com/cs/video-pruvodce-editaci/\">here</a>."}>
              </Text>

              <Subtitle className="subtitle-box" content={"$17 <span style='font-style: italic'>a night</span>"}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-8l2ce6 pb--80 pt--80" name={"contact"} css={css`
        
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/140/fullscreen_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/140/fullscreen_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/140/fullscreen_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/140/fullscreen_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/140/fullscreen_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/140/fullscreen_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/140/fullscreen_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/140/fullscreen_s=3000x_.jpg);
    }
  
      `}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim2 --anim-s8" anim={"2"} animS={"8"}>
              
              <Title className="title-box fs--62" content={"<span style='color: black'>Book a room now</span>"}>
              </Title>

              <Button className="btn-box btn-box--hvr2" content={"510-851-4014"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"footer"} style={{"backgroundColor":"var(--color-supplementary)"}}>
          
          <ColumnWrap className="column__flex el--3" anim={""} animS={"2"} style={{"maxWidth":1420}} columns={"3"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Subtitle className="subtitle-box" content={"<span style=\"color: var(--color-dominant);\">Hozelec</span>"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":366}} content={"Click and start writing. This is a text area. If needed, the color, font size, line spacing, text alignment and even the <strong>maximum width of a block of text</strong> may be customized.  If you wish to find out more about editing, click <a href=\"https://saywebpage.com/cs/video-pruvodce-editaci/\">here</a>."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Subtitle className="subtitle-box" content={"<span style=\"color: var(--color-dominant);\">About us</span>"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":366}} content={"Click and start writing. This is a text area. If needed, the color, font size, line spacing, text alignment and even the <strong>maximum width of a block of text</strong> may be customized.  If you wish to find out more about editing, click <a href=\"https://saywebpage.com/cs/video-pruvodce-editaci/\">here</a>."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--right" style={{"maxWidth":""}}>
              
              <Subtitle className="subtitle-box" content={"<span style=\"color: var(--color-dominant);\">Contact</span>"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":473}} content={"510-851-4014<br>info@vase-stranky.com<br>Created with <a href=\"https://saywebpage.com\">saywebpage.com</a>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}